//for autocomplete
import { SearchClient } from 'typesense'
import { autocomplete } from '@algolia/autocomplete-js'
import { debounce } from 'lodash'

const protocol = cm_typesense_autocomplete_default_settings.protocol !== 'https://' ? 'http' : 'https'
const TypesenseSearchClient = new SearchClient({
  'nodes': [
    {
      'host': cm_typesense_autocomplete_default_settings.node,
      'port': cm_typesense_autocomplete_default_settings.port,
      'protocol': protocol,
    }
  ],
  'apiKey': cm_typesense_autocomplete_default_settings.search_api_key,
  'numRetries': 3, // A total of 4 tries (1 original try + 3 retries)
  'connectionTimeoutSeconds': 10,
})

//for instant search

export const typesenseSenseAutoComplete = () => {

  // if (parseInt(cm_typesense_autocomplete_default_settings.hijack_wp_search) !== 1) return;
  let cmAutoCompleteElements = document.querySelectorAll('.cm-autocomplete')

  if (cmAutoCompleteElements === null || cmAutoCompleteElements.length === 0) return

  let enablePostTypes = cm_typesense_autocomplete_default_settings.enabled_post_types
  let postTypeConfig = cm_typesense_autocomplete_default_settings.search_config.post_type
  let availablePostTypes = cm_typesense_autocomplete_default_settings.available_post_types
  let autocomplete_input_delay = parseInt(cm_typesense_autocomplete_default_settings.autocomplete_input_delay)
  // autocomplete_input_delay = (autocomplete_input_delay < 300) ? 300 : autocomplete_input_delay;
  // console.log(autocomplete_input_delay);
  //https://www.algolia.com/doc/ui-libraries/autocomplete/guides/debouncing-sources/
  const debouncePromise = (fn, time) => {
    let timerId = undefined

    return function debounced (...args) {
      if (timerId) {
        clearTimeout(timerId)
      }

      return new Promise((resolve) => {
        timerId = setTimeout(() => resolve(fn(...args)), time)
      })
    }
  }

  const debounced = debouncePromise((items) => Promise.resolve(items), autocomplete_input_delay)

  const getSourcesForEnabledPostTypes = (query_by = '', collections = [], additionalSearchParams = {}) => {
    let enablePostTypes = Object.keys(collections)
    let sources = enablePostTypes.map((indexName) => {
      let postConfig = postTypeConfig[indexName]
      let maxSuggestions = (postTypeConfig[indexName] !== undefined && postTypeConfig[indexName].hasOwnProperty('max_suggestions')) ? postTypeConfig[indexName].max_suggestions : '3'

      let queryBy = query_by === '' ? 'post_title,post_content' : query_by

      // Merge default search params with additional ones

      return {
        sourceId: indexName,
        getItems ({ query }) {
          let defaultSearchParameters = {
            'q': query,
            'query_by': queryBy,
            'per_page': (maxSuggestions) ? maxSuggestions : '3'
          }

          let searchParams = { ...defaultSearchParameters, ...additionalSearchParams }

          return TypesenseSearchClient.collections(collections[indexName]).documents().search(searchParams).then((res) => {
            return res.hits
          }).catch(() => {
            return []
          })
        },
        getItemUrl ({ item }) {
          return item.document.permalink
        },
        onSelect ({ itemUrl }) {
          window.location.href = itemUrl
        },
        templates: {
          header ({ createElement }) {
            let label = availablePostTypes[indexName].label
            if (postConfig !== undefined && postConfig.hasOwnProperty('label') && postConfig.label !== '') {
              label = postConfig.label
            }
            return createElement('span', {
              dangerouslySetInnerHTML: {
                __html: wp.template('cm-autocomplete-header')({ name: label })
              }
            })
          },
          item ({ item, createElement }) {
            let highlightResults = {}
            item.formatted = {
              post_title: item.document.post_title,
              sliced_content: item.document.post_excerpt
            }
            if (item.hasOwnProperty('highlights')) {
              if (item.highlights.length > 0) {
                item._highlightResult = {}

                item._highlightResult = item.highlights.map(function (value) {
                  highlightResults[value.field] = {
                    matchedWords: value.matched_tokens,
                    value: value.snippet,
                  }
                })
                item._highlightResult = highlightResults
                if (item._highlightResult.hasOwnProperty('post_title')) {
                  item.formatted.post_title = item._highlightResult.post_title.value
                }
              }
            }

            return createElement('span', {
              dangerouslySetInnerHTML: {
                __html: wp.template('cm-autocomplete')(item),
              },
            })
          },
          noResults () {
            return wp.template('cm-autocomplete-no-results-found')()
          }
        },
      }

    })
    return sources
  }
  const sendEventDebounced = debounce((uiState) => {
    //we need to use uistate
    //console.log(uiState)
    //window.ga('set', 'page', window.location.pathname + window.location.search);
    //window.ga('send', 'pageView');
  }, 3000)

  cmAutoCompleteElements.forEach((element) => {
    let additionalSearchParams = JSON.parse(element.dataset.additional_autocomplete_params)
    let placeholder = element.dataset.placeholder
    let query_by = element.dataset.query_by
    let collections = JSON.parse(element.dataset.collections)
    let config = {}
    let siteUrl = element.dataset.site_url
    let defaultSettings = {
      container: element,
      placeholder: placeholder,
      autoSelect: true,
      getSources () {
        return debounced(getSourcesForEnabledPostTypes(query_by, collections, additionalSearchParams))
      },
      //set debug to true to design stuff
      debug: cm_typesense_autocomplete_default_settings.debug,
      onStateChange (uiState) {
        sendEventDebounced(uiState)
      },
      onSubmit (params) {
        const { state, setIsOpen, refresh } = params
        if (cm_typesense_autocomplete_default_settings.autocomplete_submit_action !== undefined && cm_typesense_autocomplete_default_settings.autocomplete_submit_action
          === 'default_wp_search'
        ) {
          window.location = siteUrl + encodeURIComponent(state.query)
        } else {
          //default action we don't need to check if it's set to keep open
          setIsOpen(true)
          refresh()
        }
      },
    }

    let customSettings = JSON.parse(element.dataset.settings)
    config = { ...defaultSettings, ...customSettings }
    autocomplete(config)
  })

}

document.addEventListener('DOMContentLoaded', () => typesenseSenseAutoComplete())

//Run Elementor dependency code only on editor
if (cm_typesense_autocomplete_default_settings.elementor_edit_mode === 'true') {
  window.addEventListener('elementor/frontend/init', () => {
    const callShortCodeHandler = () => {
      typesenseSenseAutoComplete()
    }
    elementorFrontend.hooks.addAction('frontend/element_ready/cm-typesense-autocomplete.default', callShortCodeHandler)
  })
}